//Se importa la instancia de axios
import apiClient from "../../general/services/index.js";

//Ruta a la que se va acceder de la api
const relative_path = "/afiliacion/";

export default {

  /**
   * Funcion que guarda todos los datos del contrato
   * @param {Object} titular datos del titular a guardar
   * @param {Array<Object>} beneficiarios beneficiarios relacionados con el titular
   * @param {number} temporal variable que determina si se inserta en la tabla de temporal de cada tabla en db cuando no se ha efectuado todavia el pago
   * @returns {boolean} true en el caso de que se hayan guardo los datos con exito
   */
  guardarDatosContrato(titular,beneficiarios,temporal){
    return apiClient.post(relative_path + "guardar-datos-contrato",{titular:titular,beneficiarios:beneficiarios,temporal:temporal});
  },

  /**
   * Funcion que guarda los anexos del beneficiario
   * @param {FormData} data objeto tipo formData que contiene todos los anexos del benenficiario
   * @returns {boolean} true en el caso de que se hayan guardo los datos con exito
   */
  guardarAnexosBeneficiario(data){
    return apiClient.post(relative_path + "guardar-anexos-beneficiario",data);
  },

  /**
   * Funcion que guarda los anexos del titular
   * @param {FormData} data objeto tipo formData que contiene todos los anexos del titular
   * @returns {boolean} true en el caso de que se hayan guardo los datos con exito
   */
  guardarAnexosTitular(data){
    return apiClient.post(relative_path + "guardar-anexos-titular",data);
  },

  /**
   * Funcion que trae los datos de la vista SimularTarifa.vue (Tipos de planes,Medios de pago) en una misma peticion para optimizar tiempo
   * 
   * @returns {object} datos de la vista SimularTarifa.vue (Tipos de planes,Medios de pago)
   */
  getDatosVistaCotizarTarifa() {
    return apiClient.get(relative_path + "datos-vista-cotizar-tarifa");
  },

  /**
   * Funcion que trae la tarifa y el descuento que aplica a la cotizacion realizada
   * 
   * @param {object} datos_cotizacion datos necesarios para realizar la cotizacion
   * @returns {object} tarifa y el descuento que aplica a la cotizacion realizada
   */
  generarCotizacion(datos_cotizacion) {
    return apiClient.post(relative_path + "generar-cotizacion" ,datos_cotizacion);
  },

  /**Funcion que trae todos los tipos de documentos en db */
  getTiposDocumentos(){
    return apiClient.get(relative_path + "tipos-documentos");
  },

  /**Funcion que trae todos los generos en db */
  getGeneros(){
    return apiClient.get(relative_path + "generos");
  },

  /**Funcion que trae todos los tipos de estados civiles en db */
  getEstadosCiviles(){
    return apiClient.get(relative_path + "estados-civiles");
  },

  /**Funcion que trae todos los regimenes en db */
  getRegimenes(){
    return apiClient.get(relative_path + "regimenes");
  },

  /**Funcion que trae todos las profesiones en db */
  getProfesiones(){
    return apiClient.get(relative_path + "profesiones");
  },
  
  /**Funcion que trae todos las eps en db */
  getEps(){
    return apiClient.get(relative_path + "eps");
  },

  /**Funcion que trae todos los parentescos en db */
  getParentescos(){
    return apiClient.get(relative_path + "parentescos");
  },

  /**Funcion que trae todas las enfermedades de asegurabilidad en db */
  getEnfermedadesAsegurabilidad(){
    return apiClient.get(relative_path + "enfermedades-asegurabilidad");
  },

  /**
   * Funcion que trae el descuento por ser afiliacion web
   * @param {boolean} es_asesor determinante si el usuario logueado es asesor
   * @returns {Array} el descuento por afiliacion web correspondiente
   */
  getDescuentoAfiliacionWeb(es_asesor){
    return apiClient.get(relative_path + "descuento-afiliacion-web/"+es_asesor);
  },

  /**Funcion que trae todos los medios de pago en db */
  getMediosPago(){
    return apiClient.get(relative_path + "medios-pago");
  },

  /**Funcion que trae todos los periodos de pago en db */
  getPeriodosPago(){
    return apiClient.get(relative_path + "periodos-pago");
  },

  /**Funcion que trae los datos de un contrato en el caso de que el estado de este sea igual a 8 (contrato_guardado)  */
  preContratoConsultado(numero_documento){
    return apiClient.get(relative_path + "pre-contrato-consultado/"+ numero_documento);
  },

  /**Funcion que trae todos los periodos de debito en db */
  getPeriodosDebito(){
    return apiClient.get(relative_path + "periodos-debito");
  },

  /**Funcion que trae todos los bancos en db */
  getBancos(){
    return apiClient.get(relative_path + "bancos");
  },

  /**Funcion que trae todas las ciudades con covertura para los bancos en db */
  getCiudadesConConvertura(){
    return apiClient.get(relative_path + "ciudades-con-covertura");
  },

  /**Funcion que trae todos los tipos de cuentas bancarias en db */
  getTiposCuentasBancarias(){
    return apiClient.get(relative_path + "tipos-cuentas-bancarias");
  },

  registrarIntencion(data){
    return apiClient.post(relative_path + "registrar-intencion",data);
  },

  
  
};
